import * as crystalTrackerService from './service';

export default function (config) {

    if (window.tuiCottonBall) {
        window.tuiCottonBall.cuddleMeIf('crystal-tracker', '*', 'IBE search result rendered.', function (a, b, c, d) {
            if (d.stepNumber === 4) {
                crystalTrackerService.trackProductDetailEvent(config.getAWSApiUrl(), config.getTenant(), "testEvent", d);
           // } else if (d.stepNumber === 3) {
           //     crystalTrackerService.trackProductListEvent(config.getAWSApiUrl(), config.getTenant(), "testEvent", d);
            }
        });
        window.tuiCottonBall.cuddleMeIf('crystal-tracker', '*', 'IBE checkout confirmation page rendered.', function (a, b, c, d) {
            if (window.utag_data && window.utag_data.booking_status === "RQ" || window.utag_data.booking_status === "OK") {
                crystalTrackerService.trackBookingEvent(config.getAWSApiUrl(), config.getTenant(), "testEvent", d.data);
            }
        })
    }

    if (window.localStorage && window.localStorage.getItem("crystal-tracker") !== null) {
        // eslint-disable-next-line no-console
        console.log('pageView', document.referrer, document.location, window.utag_data.page_name);
    }

    return 'crystal-tracker';
}
