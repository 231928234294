const getProductListData = (userId, eventType, searchScope, data) => {
    let productList = {
        eventType: eventType,
        pageType: "ProductList",
        data: []
    };

    if (
        window.utag_data !== undefined &&
        data.hotels !== undefined
    ) {
        for (let hotel of data.hotels) {
            if (
                hotel !== undefined &&
                hotel.bestOffer !== undefined
            ) {
                productList.data.push({
                    giataId: hotel.giataId,
                    hotelName: hotel.name,
                    hotelChain: hotel.hotelChain,
                    searchScope: searchScope,
                    user: {
                        tuiId: userId,
                        tealiumVisitorId: window.utag_data.tealium_visitor_id,
                        adobeVisitorId: window.utag_data.adobe_visitorid,
                    },
                    rooms: getRooms(hotel.bestOffer.features),
                    travelPeriod: getTravelPeriod(hotel.bestOffer.travelPeriod),
                    price: getPrice(hotel.bestOffer, data.currency),
                    location: getLocation(data, hotel)
                });
            }
        }

        // eslint-disable-next-line no-console
        console.log("crystal-tracker-service : ProductListData", data, productList);

        return productList;
    }
};

const getProductDetailData = (userId, eventType, searchScope, data) => {
    if (
        window.utag_data !== undefined &&
        data.hotel !== undefined &&
        data.offers[0] !== undefined &&
        data.country !== undefined &&
        data.region !== undefined
    ) {
        return {
            eventType: eventType,
            pageType: "ProductDetail",
            data: {
                giataId: data.hotel.giataId,
                hotelName: data.hotel.name,
                hotelChain: data.hotel.hotelChain,
                searchScope: searchScope,
                user: {
                    tuiId: userId,
                    tealiumVisitorId: window.utag_data.tealium_visitor_id || 'unknown',
                    adobeVisitorId: window.utag_data.adobe_visitorid,
                },
                rooms: getRooms(data.offers[0].features),
                travelPeriod: getTravelPeriod(data.offers[0].travelPeriod),
                price: getPrice(data.offers[0], data.currency),
                location: getLocation(data, data.hotel)
            }
        };
    } else {
        return null;
    }
};

const getBookingData = (userId, eventType, data) => {
    if (
        window.utag_data !== undefined &&
        data.hotel !== undefined &&
        data.hotel.city !== undefined &&
        data.offer !== undefined &&
        data.country !== undefined &&
        data.region !== undefined
    ) {
        return {
            eventType: eventType,
            pageType: "Booking",
            data: {
                giataId: data.hotel.giataId,
                hotelName: data.hotel.name,
                searchScope: data.searchScope,
                bookingStatus: window.utag_data.booking_status,
                user: {
                    tuiId: userId,
                    tealiumVisitorId: window.utag_data.tealium_visitor_id || 'unknown',
                    adobeVisitorId: window.utag_data.adobe_visitorid,
                },
                rooms: getRooms(data.offer.features),
                travelPeriod: getTravelPeriod(data.offer.travelPeriod),
                price: getPrice(data.offer, data.currency),
                location: getLocation(data, data.hotel),
                coupon: getCoupon()
            }
        };
    } else {
        return null;
    }
};

const getRooms = (features) => {
    if (features !== undefined) {
        let rooms = [];
        for (let feature of features) {
            let r = {};

            // board
            if (feature.board !== undefined) {
                r.board = {
                    code: feature.board.code,
                    description: feature.board.description
                };
            }

            // room
            if (feature.room !== undefined) {
                r.room = {
                    bookingCode: feature.room.bookingCode,
                    codes: feature.room.codes,
                    description: feature.room.description,
                    descriptionShort: feature.room.descriptionShort,
                    opCode: feature.room.opCode
                };
            }

            // travellers
            if (feature.travellers !== undefined) {
                r.travellers = {};
                if (feature.travellers.adults !== undefined) {
                    r.travellers.numberOfAdults = feature.travellers.adults.length;
                } 
                if (feature.travellers.children !== undefined) {
                    r.travellers.childAges = feature.travellers.children.map(child => child.age);
                    r.travellers.numberOfChildren = feature.travellers.children.length;
                }
            }

            rooms.push(r);
        }
        return rooms;
    } else {
        return null;
    }
};

const getTravelPeriod = (tp) => {
    if (tp !== undefined) {
        return {
            duration: tp.travelDuration,
            startDate: tp.travelStartDate,
            endDate: tp.travelEndDate,
            hotelDuration: tp.hotelDuration,
            hotelStartDate: tp.hotelStartDate,
            hotelEndDate: tp.hotelEndDate
        }
    } else {
        return null;
    }
};

const getPrice = (offer, currency) => {
    if (offer?.price) {
        return {
            totalPrice: offer.price.total,
            pricePerNight: Math.round(offer.price.total / offer.travelPeriod.travelDuration),
            pricePerPerson: Math.round(offer.price.single),
            currency: currency
        };
    }
};

const getLocation = (data, hotel) => {
    let l = {};
    if (data.country !== undefined) {
        l.country = {
            name: data.country.name,
            code: data.country.countryCode
        };
    }
    if (data.region !== undefined) {
        l.region = {
            name: data.region.name,
            regionId: data.region.regionId
        };
    }
    if (hotel !== undefined && hotel.city !== undefined) {
        l.city = {
            name: hotel.city
        };
    }
    return l;
};

const getCoupon = () => {
    return {
        promoCode: window.utag.data.promocode_name
    }
};

export {
    getProductListData,
    getProductDetailData,
    getBookingData
}
