/* eslint no-unused-labels: 0 */

export default {
    getComponentsHostAndPath: () => 'https://cloud.tui.com/cdn/mojo/current/tuich/',
    getLanguage: () => (window.location.href.split('/')[3] === 'fr' ? 'fr_CH' : 'de_CH'),
    getCurrency: () => 'ch',
    getTenant: () => 'TUICH',
    getSearchDiggerTenant: () => 'tui.ch',
    getVersion: () => 'tuich',
    getCampaignServiceCountry: () => 'CH',
    getCouponTemplatePath: () => 'fileadmin/tuich/coupons/coupon_config_ch.json',
    getCouponServiceUrl: () => 'https://api.cloud.tui.com/coupon/current/TUICH'
};
