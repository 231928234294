import { trackData, trackEvent, getPageName } from '../../elchspucke-tuicom.service';
import { loadScripts } from '../../util';

/**
 * Start the travel-agency-finder mojo
 * @return {string} the mojo name for event registration
 */
export default function (config) {
    /* Tracking events for the travel agency finder overview and detail pages */
    window.tuiCottonBall.subscribe('travel-agency-finder', 'window', 'overview-page-initialized', () => {
        trackData('page_name', 'travel-agency-overview', true, true);
    });

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'detail-page-initialized',
        (component, scope, event, data) => {
            trackData('page_name', 'travel-agency-detail-page-' + data.placeId, true, true);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'map-phone-number-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'agencyPhoneNumberClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'map-email-address-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'agencyEmailAddressClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'list-appointment-button-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'overviewAppointmentButtonClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'list-phone-number-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'agencyPhoneNumberClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'list-email-address-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'agencyEmailAddressClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'detail-phone-number-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'agencyPhoneNumberClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'detail-email-address-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'agencyEmailAddressClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'employee-phone-number-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'employeePhoneNumberClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'employee-email-address-clicked',
        (component, scope, event, data) => {
            trackEvent('feature', 'travel-agency-finder', 'employeeEmailAddressClicked', data.placeId);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-contact',
        '*',
        'form-submitted',
        function (component, scope, event, data) {
            if ('success' === data.status) {
                trackEvent(
                    'travel-agency-contact',
                    'travel-agency-contact-form-submitted-successfully',
                    data.variant,
                    `${data.tuiAgencyNumber}_${data.town}_${data.name}`
                );
            } else {
                trackEvent(
                    'travel-agency-contact',
                    'travel-agency-contact-form-submitted-unsuccessfully',
                    data.variant,
                    `${data.tuiAgencyNumber}_${data.town}_${data.name}`
                );
            }
        }
    );

    /* Tracking events for the travel agency contact component */
    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'travel-agency-finder-search',
        'autocomplete-focus',
        function () {
            document.querySelector('.modal-body').style.overflowY = 'auto';
            trackEvent('feature', 'terminvereinbarung_' + getPageName(), 'search', 1);
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'travel-agency-finder-search',
        'list-appointment-button-clicked',
        function (component, scope, event, data) {
            trackEvent(
                'feature',
                'terminvereinbarung_' + getPageName(),
                'list_select',
                `${data.agencyId}_${data.town}_${data.name}`
            );
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'travel-agency-finder-search',
        'map-appointment-button-clicked',
        function (component, scope, event, data) {
            trackEvent(
                'feature',
                'terminvereinbarung_' + getPageName(),
                'map_select',
                `${data.agencyId}_${data.town}_${data.name}`
            );
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-contact',
        'travel-agency-finder-search',
        'form-submitted',
        function (component, scope, event, data) {
            if ('success' === data.status && 'inquiry' !== data.variant) {
                const action = data.newsletter ? 'make_appointment_with_permission' : 'make_appointment';
                trackEvent(
                    'travel-agency-contact',
                    'terminvereinbarung_' + getPageName(),
                    action,
                    `${data.tuiAgencyNumber}_${data.town}_${data.name}`
                );
            }
        }
    );

    /* Event handler for selecting a travel agency to request an appointment */
    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'travel-agency-finder-search',
        'autocomplete-place-selected',
        function () {
            let tac = document.querySelector('tui-travel-agency-contact');
            if (null !== tac) {
                tac.remove();
            }
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-contact',
        'travel-agency-finder-search',
        'informationCloseCrossClicked',
        function () {
            let tac = document.querySelector('tui-travel-agency-contact');
            if (null !== tac) {
                tac.remove();
            }
        }
    );

    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'travel-agency-finder-search',
        'contact-request',
        function (component, scope, event, data) {
            // The new OC Contact component is used by selected travel agencies only.
            // kudiAppointmentTool === true -> display OC Contact
            const showOcContact = data.kudiAppointmentTool;
            const appointmentEl = document.getElementById('appointment');

            const hideListAndMap = () => {
                const taf = document.querySelector('travel-agency-finder');
                if (null !== taf) {
                    taf.dispatchEvent(new CustomEvent('hideListAndMap'));
                }
            };

            // Delete active contact form.
            appointmentEl.innerHTML = '';

            if (showOcContact) {
                // Load resource
                // eslint-disable-next-line
                const loadScript = new Promise((resolve, reject) => {
                    if (document.getElementById('oc-contact-script')) {
                        return resolve();
                    }

                    const $ocContactScript = document.createElement('script');
                    $ocContactScript.setAttribute(
                        'src',
                        `https://api.cloud.tui.com/oc-contact-component/current/mfe.js`
                    );
                    $ocContactScript.setAttribute('type', 'module');
                    $ocContactScript.setAttribute('id', 'oc-component-script');

                    $ocContactScript.onload = () => resolve();
                    $ocContactScript.onerror = (error) => reject(error);

                    document.body.appendChild($ocContactScript);
                })
                    // eslint-disable-next-line no-console
                    .catch((error) => console.error('OC Contact script could not be loaded', error));

                const isOcContactReady = customElements.whenDefined('tui-omnichannel-mfe');

                isOcContactReady.then(() => {
                    const lang = data.locale.split('-').shift() ?? 'de';
                    const oc_appointment = document.createElement('tui-omnichannel-mfe');
                    oc_appointment.setAttribute('agencyno', data.tuiAgencyNumber);
                    oc_appointment.setAttribute('lang', lang);
                    oc_appointment.setAttribute('testing', 'B');
                    oc_appointment.setAttribute('data-theme', 'tuiLight');

                    appointmentEl.appendChild(oc_appointment);
                    hideListAndMap();
                });
            } else {
                const taf_appointment = document.createElement('tui-travel-agency-contact');
                taf_appointment.setAttribute('scope', data.scope);
                taf_appointment.setAttribute('brand', 'TUI');
                taf_appointment.setAttribute('locale', data.locale);
                taf_appointment.setAttribute('place-id', data.placeId);
                taf_appointment.setAttribute('country', data.countryCode);
                taf_appointment.setAttribute('show-travel-agency', 'true');
                taf_appointment.setAttribute('variants', 'appointment');
                taf_appointment.setAttribute('skip-days', '1');
                taf_appointment.setAttribute('show-cross', 'true');

                appointmentEl.appendChild(taf_appointment);
                hideListAndMap();
            }
        }
    );

    /* Event handler for selecting a travel agency to forward a wishlist selection */
    window.tuiCottonBall.subscribe(
        'travel-agency-finder',
        'window',
        'agencySelected',
        function (component, scope, event, data) {
            const urlParams = new URLSearchParams(location.search);
            const favoritesId = urlParams.get('favId');
            const publicId = urlParams.get('publicId');
            const accoList = urlParams.get('accoList');
            const agencyTypes = {
                1: 'FL', // Filiale
                2: 'FR', // Franchise
                3: 'BT', // Beteiligung
                4: 'BTM', // BTM+
                5: '3rd' // Rest
            };

            if (favoritesId || publicId) {
                trackEvent('feature', 'travel-agency-finder', 'sendToAgencyClicked');

                const url = new URL('https://www.tui-kundendialog.de/OCM/MZ/');
                if (publicId) {
                    url.searchParams.set('publicId', publicId);
                } else {
                    url.searchParams.set('mzid', favoritesId);
                }
                url.searchParams.set('accoList', accoList);
                url.searchParams.set('AgencyType', agencyTypes[data.level]);
                url.searchParams.set('AgencyName', data.name);
                url.searchParams.set('AgencyAdressCity', data.town);
                url.searchParams.set('AgencyAdressZIP', data.zip);
                url.searchParams.set('AgencyAdressStreetNo', data.street);
                url.searchParams.set('AgencyEmail', data.email);
                url.searchParams.set('AgencyTel', data.phone);
                url.searchParams.set('AgencyTUIAgtNr', data.agencyId);

                if (window.location.origin.indexOf('tui.com') < 0) {
                    url.searchParams.set('test', 1);
                }
                window.open(url, '_self');
            }
        }
    );

    const consent = document.createElement('tui-consent');
    document.addEventListener('tui-consent.consent-approved', () => {
        document
            .querySelector('travel-agency-finder')
            .dispatchEvent(new CustomEvent('travel-agency-finder.consent-approved'));
        consent.remove();
    });
    document.addEventListener('map-consent.consent-approved', () => {
        document
            .querySelector('travel-agency-finder')
            .dispatchEvent(new CustomEvent('travel-agency-finder.consent-approved'));
        consent.remove();
    });

    document.addEventListener('travel-agency-finder.consentApprovalEvent', (event) => {
        loadScripts([
            `${config.getAWSCdnUrl()}consent/current/consent.js`,
            `${config.getAWSCdnUrl()}map-consent/current/map-consent.js`
        ]).then(() => {
            document.dispatchEvent(
                new CustomEvent('tui-consent.is-approved', {
                    detail: {
                        cb: (approved) => {
                            document.querySelector('.modal-dialog').style.overflowY = 'visible';
                            if (!approved) {
                                consent.setAttribute('data-theme', 'tui');
                                consent.setAttribute('data-legacy-font', '');
                                event.detail.searchNode.parentNode.insertBefore(consent, event.detail.searchNode);

                                const mapConsent = document.createElement('tui-map-consent');
                                mapConsent.setAttribute('data-theme', 'tui');
                                mapConsent.setAttribute('data-legacy-font', '');
                                event.detail.mapNode.appendChild(mapConsent, event.detail.mapNode);

                                const observer = new MutationObserver(() => {
                                    const modalBody = document.querySelector('.modal-body');
                                    if (consent?.shadowRoot?.querySelector('.info-box') && modalBody) {
                                        modalBody.style.minHeight = '30rem';
                                    } else {
                                        modalBody.style.minHeight = 'initial';
                                    }
                                });

                                observer.observe(consent.shadowRoot, {
                                    attributes: true,
                                    childList: true,
                                    characterData: true,
                                    subtree: true
                                });
                            } else {
                                document.querySelector('.modal-dialog').style.overflowY = 'auto';
                                document
                                    .querySelector('travel-agency-finder')
                                    .dispatchEvent(new CustomEvent('travel-agency-finder.consent-approved'));
                            }
                        }
                    }
                })
            );
        });
    });

    document.addEventListener('travel-agency-finder.componentLoads', () => {
        document.querySelector('.modal-dialog .icon--close').style.right = '2rem';
        const finder = document.querySelector('travel-agency-finder');
        finder.removeAttribute('types');
    });

    document.addEventListener('omnichannel-mfe.submitted', (event) => {
        const { status, agencyNo, name, town } = event.detail;
        if ('success' === status) {
            trackEvent(
                'omni-channel',
                'terminvereinbarung_' + getPageName(),
                'make_appointment',
                `${agencyNo}_${town}_${name}`
            );
        }
    });
}
