import {getSearchParameterFromUrl, getPageLanguage, getCurrentUser, trackEvent} from "../../elchspucke-tuicom.service.js";

const handleErrors = (response) => {
    if (!response.ok) {
        throw Error(response.statusText);
    }

    return response.json();
};

const getHotelData = (data) => {
    const hotelData = {};
    hotelData.userData = {
        userAgent: navigator.userAgent
    };

    const searchParams = getSearchParameterFromUrl();
    hotelData.searchParameter = {
        startDate: searchParams.startDate,
        endDate: searchParams.endDate,
        duration: searchParams.duration,
        departureMinTime: searchParams.departureMinTime,
        departureMaxTime: searchParams.departureMaxTime,
        returnMinTime: searchParams.returnMinTime,
        returnMaxTime: searchParams.returnMaxTime,
        minPrice: searchParams.minPrice,
        maxPrice: searchParams.maxPrice,
        searchScope: searchParams.searchScope ? searchParams.searchScope : 'PACKAGE',
        rooms: []
    };

    if (searchParams.departureAirports) {
        hotelData.searchParameter.departureAirports = searchParams.departureAirports.split(';');
    }
    if (searchParams.airlines) {
        hotelData.searchParameter.airlines = searchParams.airlines.split(';');
    }
    if (searchParams.maxStopOver) {
        hotelData.searchParameter.maxStopOver = parseInt(searchParams.maxStopOver, 10);
    }
    if (searchParams.operators) {
        hotelData.searchParameter.operators = searchParams.operators.split(';');
    }

    const numberOfAdults = searchParams.adults ? searchParams.adults.split(','):[2];
    const childAges = searchParams.childs? searchParams.childs.split(','): [];
    for (let index=0; index<numberOfAdults.length; index++) {
        const room = {
            adults: parseInt(numberOfAdults[index], 10)
        };
        if (childAges[index] && childAges[index].length > 0) {
            room.children = childAges[index].split(';').map(age => parseInt(age, 10));
        }
        if (searchParams.boardTypes && searchParams.boardTypes.length > 0) {
            room.boardTypes = searchParams.boardTypes.split(';');
        }
        if (searchParams.roomTypes && searchParams.roomTypes.length > 0) {
            room.roomTypes = searchParams.roomTypes.split(';');
        }
        if (searchParams.roomOpCodes && searchParams.roomOpCodes.length > 0) {
            room.roomCodes = searchParams.roomOpCodes.split(';');
        }
        hotelData.searchParameter.rooms.push(room);
    }

    if (data.data) {
        const hotel = data.data.hotel;
        if (hotel) {
            hotelData.hotel = {
                giataId: hotel.giataId
            };
        }
    }

    if (data.response) {
        const response = data.response;
        if (response) {
            hotelData.searchResult = {
                cheapestTotalPrice: response.cheapestOffer?.price?.total || null,
                count: response.offerCountResults? response.offerCountResults: null
            };
        }
    }

    return hotelData;
};

var supports_local_storage = function() {
    try {
        return 'localStorage' in window && window['localStorage'] !== null;
    } catch(e){
        return false;
    }
};

var getItemFromLocalStorage = function(key) {
    if (supports_local_storage()) {
        return window.localStorage.getItem(key);
    }
    return false;
};

var setItemInLocalStorage = function(key, value) {
    if (supports_local_storage()) {
        window.localStorage.setItem(key, value);
    }
};

const addHotelToLocalStorage = (data) => {
    if (data.data) {
        const hotel = data.data.hotel;
        if (hotel) {
            const entry = {giataId: hotel.giataId, datetime: new Date(), ctaUrl: window.location.href, searchEntry: getHotelData(data)};
            let rememberedHotelsWithCtaUrls = [];
            const localStorageValue = getItemFromLocalStorage('rememberedHotelsWithCtaUrls');
            if (localStorageValue) {
                rememberedHotelsWithCtaUrls = JSON.parse(localStorageValue);
            }
            if (hotel.giataId && typeof hotel.giataId === 'number' && rememberedHotelsWithCtaUrls instanceof Array) {
                if (rememberedHotelsWithCtaUrls.map(function(entry) { return entry.giataId; }).indexOf(hotel.giataId) !== -1) {
                    rememberedHotelsWithCtaUrls.splice(rememberedHotelsWithCtaUrls.map(function(entry) { return entry.giataId; }).indexOf(hotel.giataId),1);
                }
                rememberedHotelsWithCtaUrls.unshift(entry);
                rememberedHotelsWithCtaUrls=rememberedHotelsWithCtaUrls.slice(0,10);
                setItemInLocalStorage('rememberedHotelsWithCtaUrls', JSON.stringify(rememberedHotelsWithCtaUrls));
            }
        }
    }
};

const getHotelsFromLocalStorage = (callback) => {
    let rememberedHotels = [];
    const localStorageValue = getItemFromLocalStorage('rememberedHotelsWithCtaUrls');
    if (localStorageValue) {
        rememberedHotels = JSON.parse(localStorageValue);
    }
    callback(rememberedHotels);
};

const addHotel = (awsApiUrl, tenancy, token) => {
    if (token) {
        while (searchHistoryData.length > 0) {
            window.fetch(`${awsApiUrl}/search-history/current/${tenancy}/hotel/add`,
                {
                    method: 'POST',
                    cache: 'no-cache',
                    redirect: 'follow',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-TUI-RequestId': 'search-history',
                        'token': token
                    },
                    body: JSON.stringify(getHotelData(searchHistoryData.shift()))
                })
                .then(handleErrors)
                .then((res) => {
                    return res;
                })
                .catch((error) => {
                    return error;
                });
        }
    }
};

const getFilterData = (data) => {
    const filterData = {};
    if (data.startDate) {
        filterData.startDate = data.startDate;
    }
    if (data.endDate) {
        filterData.endDate = data.endDate;
    }
    if (data.duration) {
        filterData.duration = data.duration;
    }
    if (data.departureAirports && data.departureAirports.length > 0) {
        filterData.departureAirports = data.departureAirports.join(';')
    }
    if (data.airlines && data.airlines.length > 0) {
        filterData.airlines = data.airlines.join(';')
    }
    if (data.maxStopOver >= 0) {
        filterData.maxStopOver = data.maxStopOver.toString();
    }
    if (data.departureMinTime) {
        filterData.departureMinTime = data.departureMinTime;
    }
    if (data.departureMaxTime) {
        filterData.departureMaxTime = data.departureMaxTime;
    }
    if (data.returnMinTime) {
        filterData.returnMinTime = data.returnMinTime;
    }
    if (data.returnMaxTime) {
        filterData.returnMaxTime = data.returnMaxTime;
    }
    if (data.minPrice) {
        filterData.minPrice = data.minPrice;
    }
    if (data.maxPrice) {
        filterData.maxPrice = data.maxPrice;
    }
    if (data.operators && data.operators.length > 0) {
        filterData.operators = data.operators.join(';')
    }
    if (data.searchScope) {
        filterData.searchScope = data.searchScope;
    }
    if (data.rooms && Array.isArray(data.rooms)) {
        filterData.adults = data.rooms.map(room => room.adults).join(',');
        filterData.childs = data.rooms.map((room) => {
            if (room.children && Array.isArray(room.children)) {
                return room.children.join(';');
            }
            return '';
        }).join(',');
        if (data.rooms[0].boardTypes) {
            filterData.boardTypes = data.rooms[0].boardTypes.join(';');
        }
        if (data.rooms[0].roomTypes) {
            filterData.roomTypes = data.rooms[0].roomTypes.join(';');
        }
    }
    return filterData;
};

const openHotelDetailPage = (plattformUrl, data) => {
    if (data.ctaUrl) {
        const url = new URL(data.ctaUrl);
        if (data.variant) {
            if (data.giataId) {
                url.searchParams.set('contentid', 'SH-' + data.variant.toUpperCase() + '_' + data.giataId);
            } else {
                url.searchParams.set('contentid', 'SH-' + data.variant.toUpperCase());
            }
        }
        window.open(url, '_blank');
    } else {
        var lang = getPageLanguage() + '/';

        var scopePath = 'pauschalreisen';
        if (data.searchParameter && data.searchParameter.searchScope === 'HOTEL') {
            scopePath = 'hotel';
        }
        var urlString = plattformUrl + '/' + lang + scopePath + '/suchen/angebote';
        urlString += '/' + data.hotelName;
        urlString += '/' + data.giataId;
        urlString += '/id';
        const url = new URL(urlString);

        const filterData = getFilterData(data.searchParameter);
        for (var key in filterData) {
            url.searchParams.set(key, filterData[key]);
        }
        if (data.variant) {
            url.searchParams.set('contentid', 'SH-' + data.variant.toUpperCase() + '_' + data.giataId);
        }
        window.open(url, '_blank');
    }
};

const injectCrystalCards = (data) => {
    const searchHistoryNode = document.querySelector('.tui-component-search-history');
    const crystalCardsWrapperId = 'crystalCardsWrapper';
    let crystalCardsWrapper = document.getElementById(crystalCardsWrapperId);
    if (data.numberOfLastSeen < data.minimumLastSeen) {
        const crystalCardsHeaderId = 'crystalCardsOnHome';
        if (!crystalCardsWrapper) {
            crystalCardsWrapper = document.createElement('div');
            crystalCardsWrapper.setAttribute('id', crystalCardsWrapperId);
            searchHistoryNode.appendChild(crystalCardsWrapper);

            const crystalHeader = document.createElement('h2');
            crystalHeader.setAttribute('id', crystalCardsHeaderId);
            crystalHeader.setAttribute('class', 'grid-heading u-f-24 / text-uppercase');
            crystalCardsWrapper.appendChild(crystalHeader);
        }

        window.tuiCottonBall.broadcast('recommendations', 'Load crystal-ball hotels.', {
            headerId: crystalCardsHeaderId,
            type: 'topHotels',
            properties: {searchScope: 'PACKAGE', intervalInDays: 42, cardType: 'medium'}
        });
    } else {
        const currentUser = getCurrentUser();
        if (currentUser && currentUser.token) {
            trackEvent('feature', 'SH_LASTSEEN_USER', 'display', data.numberOfLastSeen);
        } else {
            trackEvent('feature', 'SH_LASTSEEN', 'display', data.numberOfLastSeen);
        }
        if (crystalCardsWrapper) {
            searchHistoryNode.removeChild(crystalCardsWrapper);
        }
    }
};

const searchHistoryData = [];
const rememberHotel = (data) => {
    if (data.stepNumber === 4) {
        searchHistoryData.push(data);
        window.tuiCottonBall.broadcast('login', 'Get login state.', {});
        addHotelToLocalStorage(data);
    }
};

export { rememberHotel, addHotelToLocalStorage, getHotelsFromLocalStorage, addHotel, getFilterData, openHotelDetailPage, injectCrystalCards }
