import * as ElchspuckeService from '../../elchspucke-tuicom.service';
import { createHTMLNode } from '../../util';

const overlayId = 'tui-login-overlay';
const overlayIconId = 'tui-login-overlay-icon';
const overlayTitleId = 'tui-login-overlay-title';
const loginModalCloseId = 'tui-login-modal-close';
const icon = 'https://static.tui.com/assets/v2/logos/tui/tui-logo-tile-white.svg';

const iconVerification = '<svg width="54" height="40" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">\n' +
    '    <defs>\n' +
    '        <path id="a" d="M.08035429.01669065H15.9318857V15.982446H.08035429z"/>\n' +
    '    </defs>\n' +
    '    <g fill="none" fill-rule="evenodd">\n' +
    '        <path fill="#E7E3DB" d="M0 40h48V8H0z"/>\n' +
    '        <path fill="#D7D2C3" d="M0 8v3.0099275L24 27l24-15.9900725V8z"/>\n' +
    '        <path fill="#E7E3DB" d="M24 8H0l12 8 12 8 12-8 12-8z"/>\n' +
    '        <g transform="translate(38)">\n' +
    '            <mask id="b" fill="#fff">\n' +
    '                <use xlink:href="#a"/>\n' +
    '            </mask>\n' +
    '            <path d="M15.9318971 7.99959712c0 4.40874818-3.5484571 7.98284888-7.92582853 7.98284888-4.37725714 0-7.92571428-3.5741007-7.92571428-7.98284888 0-4.40886331 3.54845714-7.98296403 7.92571428-7.98296403 4.37737143 0 7.92582853 3.57410072 7.92582853 7.98296403" fill="#D41118" mask="url(#b)"/>\n' +
    '        </g>\n' +
    '        <path d="M46.38166857 11.7651108c-.19657143 0-.34868571-.010705-.45645714-.032-.108-.021295-.18491429-.053295-.2312-.096-.0848-.0698705-.12708572-.1862446-.12708572-.3492374V6.5914705l-.48537142.4537554c-.21188572.18256115-.39108572.27361151-.53748572.27361151-.11931428 0-.21942857-.03004317-.30045714-.0902446-.08068572-.06020144-.15794286-.13283454-.23108572-.21824461-.0656-.08529496-.12525714-.17542446-.17908571-.2706187-.05405714-.09496403-.08091429-.19879137-.08091429-.3112518 0-.08541007.0568-.19015828.17051429-.31447482.11348571-.12397123.25131429-.25404317.41314286-.3897554l1.30011428-1.10008633c.20011429-.17058993.37565715-.28408633.52571429-.3403741.1504-.05605755.29074286-.0843741.42194286-.0843741.18857142 0 .33794285.05433093.44765714.1628777.10971428.10877698.1648.2793669.1648.51223021v6.41335254c0 .097036-.00971429.1697842-.02891429.2182446-.01942857.0485755-.05302857.0932374-.10114285.1338705-.04822858.0408633-.12708572.0718273-.23691429.0931223-.10971429.021295-.2592.032-.44777143.032" fill="#FFF"/>\n' +
    '    </g>\n' +
    '</svg>';

const translation = {
    "loginButton": {
        "de": "Anmelden",
        "fr": "S'inscrire",
    },
    "logoutButton": {
        "de": "Abmelden",
        "fr": "Se désinscrire",
    },
    "loggedIn": {
        "de": "myTUI Konto",
        "fr": "Compte myTUI"  
    },
    "newCustomer": {
        "de": "Neuer Kunde?",
        "fr": "Nouveau ici?"
    },
    "createAccount": {
        "de": "Konto erstellen",
        "fr": "Créer un compte"
    },
    "policyLink": {
        "de": "https://www.tui.ch/de/datenschutz/",
        "fr": "https://www.tui.ch/fr/avis-de-confidentialite/"
    },
    "termsLink": {
        "de": "https://www.tui.ch/de/service-kontakt/avrb/",
        "fr": "https://www.tui.ch/fr/service-contact/cgvdc/"
    },
    "cookieLink": {
        "de": "https://www.tui.ch/de/cookie-hinweis/",
        "fr": "https://www.tui.ch/fr/avis-sur-les-cookies/"
    },
    "overlayTitle": {
        "de": "Einloggen",
        "fr": "Se connecter"
    },
    "dropdownTitle": {
        "de": "Einloggen oder Registrieren",
        "fr": "Se connecter ou s'inscrire"
    },
    "myTui": {
        "de": "myTUI",
        "fr": "myTUI (en allemand)"
    },
    "bookings": {
        "de": "Meine Buchungen",
        "fr": "Mes réservations"
    },
    "verification": {
        "user": {
            "de": "Ihre E-Mail-Adresse",
            "fr": "Votre adresse e-mail"
        },
        "textMail": {
            "de": "Wir haben Ihnen einen <span>Aktivierungslink</span> an <span>' ${user} '</span> gesendet.",
            "fr": "Nous vous avons envoyé un <span>lien d'activation</span> à <span>' ${user} '</span>"
        },
        "textConfirmation": {
            "de": "Bitte bestätigen Sie Ihre Registrierung, indem Sie <span>dem Link in der E-Mail folgen.</span>",
            "fr": "Veuillez confirmer votre inscription en <span>suivant le lien contenu dans l'e-mail.</span>"
        },
        "confirmationButton": {
            "de": "OK",
            "fr": "O.K."
        }
    },
    "mySearchHistory": {
        "de": {
            "title": "Mein Suchverlauf",
            "link": "/de/suchverlauf",
        },
        "fr": {
            "title": "L'historique de mes recherches",
            "link": "/fr/historique-des-recherches"
        }
    },
    "myCoupons": {
        "de": {
            "label": "Neu",
            "title": "Persönliche Coupons",
            "link": "/de/meine-coupons",
        },
        "fr": {
            "label": "Nouveau",
            "title": "Coupons personnels",
            "link": "/fr/mes-coupons"
        }
    },
    "myFavorites": {
        "de": {
            "title": "Mein Merkzettel",
            "link": "/de/merkzettel",
        },
        "fr": {
            "title": "Mes favoris",
            "link": "/fr/memo"
        }
    }
}

const location = ElchspuckeService.getLanguage('CH')

const getLanguage = (tenant) => {
    return tenant.split('_')[0];
}

const language = getLanguage(location);

const loginPanel = createHTMLNode(
    'div', 
    'tui-component tui-component-login', 
    'loginPanel',
    [
        { key: 'data-theme', value: 'tuiLight' },
        { key: 'data-tenant', value: 'TUICH' },
        { key: 'data-lang', value: location },
        { key: 'data-visible', value: 'true' },
        { key: 'data-variant', value: 'loginPanel' },
        { key: 'data-label', value: translation.loginButton[language] },
        { key: 'data-authority', value: 'MEINETUI' },
        { key: 'data-event-scope', value: 'login' },
        { key: 'data-recaptcha-site-key', value: '6Lekkz8UAAAAAI1UFqZQ4ZUpTLCyqzcSZt9-l48z' },
        { key: 'data-recaptcha-policy-link', value: translation.policyLink[language] },
        { key: 'data-recaptcha-terms-link', value: translation.termsLink[language] },
        { key: 'data-recaptcha-cookie-link', value: translation.cookieLink[language] }
    ]
);

const hideOverlay = () => {
    let overlayElement = document.getElementById(overlayId);
    if (overlayElement && overlayElement.parentNode) {
        overlayElement.classList.remove('shown');
        overlayElement.parentNode.removeChild(overlayElement);
    }

    let html = document.getElementsByTagName('html')[0];
    html.style.overflow = '';
};

const buildOverlayElement = (panel, variant) => {
    let overlayElement = document.getElementById(overlayId);
    let overlayIcon = document.getElementById(overlayIconId);
    let overlayTitle = document.getElementById(overlayTitleId);
    let overlayTitleText = document.querySelector('.tui-login-overlay-title-text');
    let modalWrapper = document.querySelector('.tui-login-modal-wrapper');
    let closeButton = document.querySelector('.tui-icon--close');
    let loginModal = document.querySelector('.tui-login-modal');

    if (!overlayElement) {
        const className = variant ? 'tui-login-overlay ' + variant : 'tui-login-overlay';
        overlayElement = createHTMLNode('div', className, overlayId);
        document.body.appendChild(overlayElement);
    }

    if (!overlayTitle) {
        overlayTitle = createHTMLNode('div', 'tui-login-overlay-title', overlayTitleId);
        overlayElement.appendChild(overlayTitle);
    }

    if (!overlayTitleText) {
        overlayTitleText = createHTMLNode('span', 'tui-login-overlay-title-text');
        overlayTitleText.innerHTML = translation.overlayTitle[language];
        overlayTitle.appendChild(overlayTitleText);
    }

    if (!overlayIcon) {
        overlayIcon = createHTMLNode('div', 'tui-login-overlay-icon', overlayIconId);
        const overlayIconImg = createHTMLNode('img', 'tui-login-overlay-icon-img', 'tui-login-overlay-icon-img');
        overlayIconImg.src = icon;
        overlayIcon.appendChild(overlayIconImg);
        overlayTitle.appendChild(overlayIcon);
    }

    if(!modalWrapper) {
        modalWrapper = createHTMLNode('div', 'tui-login-modal-wrapper');
    }

    if (!closeButton) {
        closeButton = createHTMLNode('i', 'tui-icon--close tui-login-modal-close', loginModalCloseId);
        closeButton.addEventListener('click', () => {
            hideOverlay();
            checkVariant(variant);
        });
        modalWrapper.appendChild(closeButton);
    }

    overlayElement.appendChild(modalWrapper);

    if(!loginModal) {
        loginModal = createHTMLNode('div', 'tui-login-modal', 'tui-login-modal');
        loginModal.appendChild(panel);
        modalWrapper.appendChild(loginModal);
    }

    overlayElement.addEventListener('click', (event) => {
        if (event.target === overlayElement && event.target.classList.contains('shown')) {
            hideOverlay();
        }
        checkVariant(variant);
    }, false);

    let html = document.getElementsByTagName('html')[0];
    if (overlayElement && html) {
        overlayElement.classList.add('shown');
        html.style.overflow = 'hidden';
    }

    window.tuiCottonBall.broadcast('login', 'hit me baby one more time.', {});

    return overlayElement;
};

const checkVariant = (variant) => {
    if (variant === 'verification') {
        removeQueryParam();
    }
};

const showOverlay = () => {
    return buildOverlayElement(loginPanel, 'login');
};

const encodedRegistrationInformation = () => {
    const currentUrl = new URL(document.location);
    currentUrl.searchParams.append('registered', 1);
    return encodeURIComponent(currentUrl);
};

const jumpToMeineTui = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'meine-tui', window.utag_data ? window.utag_data.page_name : '');
    window.open('https://www.my.tui.ch/', '_blank');
};

const jumpToForgotPassword = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'passwort-vergessen', window.utag_data ? window.utag_data.page_name : '');
    window.location.href = '/' + language + '/reset-password';
};

const jumpToRegistration = (contentId) => {
    if (contentId !== "register_TUI_login") {
        contentId = "register_myTUI_navigation";
    }
    const encodedParameter = encodedRegistrationInformation();
    const register = language === 'fr' ? '/enregistrer' : '/registrieren';
    const url = 'https://www.tui.ch/' + language + register + '?redirecturl=' + encodedParameter;
    sessionStorage.setItem("contentid", contentId);
    window.open(url, '_self');
};

const jumpToMyBookingsPage = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'meine-buchungen', window.utag_data ? window.utag_data.page_name : '');
    window.open('https://www.my.tui.ch/', '_blank');
};

const jumpToCouponsPage = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'coupons', window.utag_data ? window.utag_data.page_name : '');
    window.location.href = translation.myCoupons[language].link;
};

const jumpToSearchHistoryPage = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'suchverlauf', window.utag_data ? window.utag_data.page_name : '');
    window.location.href = translation.mySearchHistory[language].link;
};

const jumpToFavoritesPage = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'merkzettel', window.utag_data ? window.utag_data.page_name : '');
    window.location.href = translation.myFavorites[language].link;
};

const logout = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'out', window.utag_data ? window.utag_data.page_name : '');
    window.tuiCottonBall.broadcast('login', 'User logout.', {authority: 'MEINETUI'});
};

const menuItem = (id, iconPath, title, onClick, badge = "") => {
    const dropdownItem = document.createElement('li');
    dropdownItem.setAttribute('class', 'tui-login-menu__item');
    
    const item = document.createElement('div');
    item.setAttribute('class', 'tui-login-menu__item--link');
    item.setAttribute('id', id);
    if (onClick) {
        item.setAttribute('onclick', onClick.bind(this));
        item.onclick = onClick;
    }

    if (iconPath !== "") {
        const iconImage = createHTMLNode('img', 'tui-login-menu__item--img', `${id + '-img'}`);
        iconImage.src = iconPath;
        item.appendChild(iconImage);
    }
    
    const textWrapper = createHTMLNode('div', 'tui-login-menu-item-wrapper', 'item-wrapper');
    if (title !== "") {
        const titleNode = createHTMLNode('span', 'tui-login-menu__item--title', `${id + '-title'}`);
        titleNode.innerHTML = title;
        textWrapper.appendChild(titleNode);
    }
    item.appendChild(textWrapper);

    if (badge !== "") {
        const newBadge = createHTMLNode('ul', 'eyecatcher');
        newBadge.innerHTML = badge;
        item.appendChild(newBadge);
    }
    
    dropdownItem.appendChild(item);

    return dropdownItem;
};

const registrationMenuItem = (entryLabel, entryLink, onClick) => {
    const dropdownItem = document.createElement('li');
    dropdownItem.setAttribute('class', 'tui-list__item');

    const item = document.createElement('div');
    item.setAttribute('class', 'tui-registration');

    const label = document.createElement('span');
    label.setAttribute('class', 'tui-registration__label');

    const registrationLink = document.createElement('span');
    registrationLink.setAttribute('class', 'tui-link');

    if (onClick) {
        item.setAttribute('onclick', onClick.bind(this));
        item.onclick = onClick;
    }
    label.innerHTML = entryLabel;
    registrationLink.innerHTML = entryLink;

    item.appendChild(label);
    item.appendChild(registrationLink);
    dropdownItem.appendChild(item);
    return dropdownItem;
};

const getCurrentUserClaims = () => {
    const userData = ElchspuckeService.getCurrentUser();
    if (userData && userData.token && userData.token.length > 0) {
        var base64Url = userData.token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }
};

const _loginItem = (id, className) => {
    const wrapper = createHTMLNode('div', className, id);
    const innerWrapper = createHTMLNode('div', 'tui-membership__wrapper', 'tui-membership__wrapper');

    const button = createHTMLNode('button', 'tui-membership__wrapper--button', 'button');
    button.innerHTML = translation.loginButton[language];
    innerWrapper.appendChild(button);
    button.addEventListener('click', function () {
        showOverlay();
    });

    innerWrapper.appendChild(registrationMenuItem(translation.newCustomer[language], translation.createAccount[language], jumpToRegistration));

    wrapper.appendChild(innerWrapper);

    return wrapper;
};

const addDropdownMenu = (loginMetaNavPosition) => {
    const claims = getCurrentUserClaims();
    const dropdownToRemove = document.getElementById('tui-login-dropdown');
    if (dropdownToRemove) {
        dropdownToRemove.parentNode.removeChild(dropdownToRemove);
    }

    const wrapperContainer = createHTMLNode('div', 'tui-login-dropdown', 'tui-login-dropdown');
    const wrapper = createHTMLNode('div', 'tui-login-dropdown__wrapper', 'tui-login-dropdown__wrapper');

    wrapperContainer.appendChild(wrapper);

    const dropdownMenu = createHTMLNode('ul', 'tui-login-menu', 'tui-login-menu');
    
    const logo = createHTMLNode('img', 'tui-login-logo');
    logo.src = icon;
    wrapper.appendChild(logo);

    const title = createHTMLNode('div', 'tui-login-title');
    title.innerHTML = translation.dropdownTitle[language];
    wrapper.appendChild(title);

    if (claims) {
        title.innerHTML = translation.loggedIn[language];

        const loggedInUser = document.createElement('div');
        loggedInUser.classList.add('tui-login-user');
        
        if (claims.user.includes('@')) {
            const splittedUserClaim = claims.user.split('@')[0];
            const splittedUserOperator = claims.user.split('@')[1];
            const charLength = splittedUserClaim.length;

            if (charLength > 30) {
                const user = createHTMLNode('span', '');
                const mailOperator = createHTMLNode('span', '');
                user.innerHTML = splittedUserClaim;
                mailOperator.innerHTML = '@' + splittedUserOperator;
                loggedInUser.appendChild(user);
                loggedInUser.appendChild(mailOperator);
            }
        } else {
            loggedInUser.innerHTML = claims.user;
        }
        
        dropdownMenu.classList.add('loggedin');

        wrapper.appendChild(loggedInUser);
    } else {
        dropdownMenu.classList.remove('loggedin');

        const loginItem = _loginItem('tui-membership__dropdown', 'tui-membership__dropdown');
        wrapper.appendChild(loginItem);
    }
    
    const items = [
        menuItem('mytui', "https://static.tui.com/assets/v2/icons/tui-light/user-circled.svg", translation.myTui[language], jumpToMeineTui),
        menuItem('coupons', "https://static.tui.com/assets/v2/icons/tui-light/percent-sign.svg", translation.myCoupons[language].title, jumpToCouponsPage, translation.myCoupons[language].label),
        menuItem('suchverlauf', "https://static.tui.com/assets/v2/icons/tui-light/search.svg", translation.mySearchHistory[language].title, jumpToSearchHistoryPage),
        menuItem('merkzettel', "https://static.tui.com/assets/v2/icons/tui-light/heart.svg", translation.myFavorites[language].title, jumpToFavoritesPage),
        menuItem('buchungen', "https://static.tui.com/assets/v2/icons/tui-light/digital-service.svg", translation.bookings[language], jumpToMyBookingsPage)
    ];
    
    items.forEach((item) => {
        dropdownMenu.appendChild(item);
    });

    wrapper.appendChild(dropdownMenu);

    if (claims) {
        dropdownMenu.appendChild(menuItem("logout", "https://static.tui.com/assets/v2/icons/tui-light/logout.svg", translation.logoutButton[language], logout));
    }
    wrapper.addEventListener('click', function () { hideDropdown() }, false);
    
    loginMetaNavPosition.appendChild(wrapperContainer);
};

const openDropdown = () => {
    const loginMenu = document.querySelector('.tui-login-dropdown');
    const bodyItem = document.querySelector('body');
    if (loginMenu.style.display === 'block') {
        bodyItem.classList.remove('dropdown-list-active');
        loginMenu.style = null;
    } else {
        if (bodyItem.classList.contains('main-nav-active')) {
            bodyItem.classList.remove('main-nav-active');
            const mainNav = document.querySelector('.js-main-nav');
            if (mainNav.classList.contains('main-nav--open')) {
                const menuIcon = document.querySelector('.js-menu-icon');
                mainNav.classList.remove('main-nav--open');
                menuIcon.classList.remove('icon--close');
                menuIcon.classList.add('icon--menu');
            }
        }

        bodyItem.classList.add('dropdown-list-active');
        loginMenu.style.display = 'block';
    }
};

const hideDropdown = () => {
    const loginMenu = document.querySelector('.tui-login-menu');
    loginMenu.style = null;
};

const initialize = (loginMetaNavPosition) => {
    document.addEventListener('keyup', function (e) {
        if (e.keyCode === 27) {
            hideOverlay();
        }
    });
    addDropdownMenu(loginMetaNavPosition);
};

const onLogin = () => {
    ElchspuckeService.trackEvent('feature', 'login', 'in', window.utag_data ? window.utag_data.page_name : '');
    hideOverlay();
};

const onPasswordForgotten = () => {
    hideOverlay();
    jumpToForgotPassword();
};

const onGoToRegistration = (contentId) => {
    hideOverlay();
    jumpToRegistration(contentId);
};

const removeQueryParam = () => {
    const currentUrl = new URL(document.location);
    if (currentUrl.searchParams) {
        currentUrl.searchParams.delete('registered');
    }
    window.tuiCottonBall.broadcast('IBE', 'New filters were set.', { 'registered': null });

    return window.history.replaceState(null, null, currentUrl.href);
};

const createVerificationPanel = () => {
    const verificationPanel = createHTMLNode('div', 'tui-verification-modal');
    const verificationIconNode = createHTMLNode('div', 'tui-verification-icon');
    verificationIconNode.innerHTML = iconVerification;

    const verificationTextWrapper = createHTMLNode('div', 'tui-verification-text');
    const verificationTextMail = createHTMLNode('p', 'tui-verification-mail-text');

    const claims = getCurrentUserClaims();
    const user = claims && claims.user ? claims.user : translation.verification.user[language];
    const text = translation.textMail[language];
    const replacedText = text.replaceAll('${user}', user);
    verificationTextMail.innerHTML = replacedText;

    const verificationTextConfirmation = createHTMLNode('p', 'tui-verification-mail-text');
    verificationTextConfirmation.innerHTML = translation.verification.textConfirmation[language];

    const confirmationButton = createHTMLNode('button', 'tui-btn tui-btn--md tui-btn--default tui-text--uppercase');
    confirmationButton.innerHTML = translation.verification.confirmationButton[language];
    confirmationButton.addEventListener('click', () => {
        hideOverlay();
        removeQueryParam();
        ElchspuckeService.trackEvent('feature', 'registration', 'activation-request', window.utag_data ? window.utag_data.page_name : '');
    });

    verificationTextWrapper.appendChild(verificationTextMail);
    verificationTextWrapper.appendChild(verificationTextConfirmation);

    verificationPanel.appendChild(verificationIconNode);
    verificationPanel.appendChild(verificationTextWrapper);
    verificationPanel.appendChild(confirmationButton);

    return verificationPanel;
};

const showVerification = () => {
    const verifitcationPanel = createVerificationPanel();
    return buildOverlayElement(verifitcationPanel, 'verification');
};

export { initialize, openDropdown, onLogin, onPasswordForgotten, showOverlay, addDropdownMenu, onGoToRegistration, showVerification }
