import * as trackingData from './trackingData.js';

const trackProductListEvent = (url, tenant, name, data) => {
    const eventType = getEventType();
    if (eventType !== '') {
        const trackerData = trackingData.getProductListData(getUserId(), getEventType(), data.searchScope, data.response);
        sendEvent(url, tenant, name, trackerData);
    }
};

const trackProductDetailEvent = (url, tenant, name, data) => {
    const eventType = getEventType();
    if (eventType !== '') {
        const trackerData = trackingData.getProductDetailData(getUserId(), eventType, data.searchScope, data.response);
        sendEvent(url, tenant, name, trackerData);
    }
};

const trackBookingEvent = (url, tenant, name, data) => {
    const eventType = getEventType();
    if (eventType !== '') {
        const trackerData = trackingData.getBookingData(getUserId(), eventType, data);
        sendEvent(url, tenant, name, trackerData);
    }
};

let currentPathname;
let currentSearch;

const getEventType = () => {
    if (document.location.pathname !== currentPathname) {
        currentPathname = document.location.pathname;
        return 'PageView';
    } else if (document.location.search !== currentSearch) {
        currentSearch = document.location.search;
        return 'UserAction';
    }
    return ''
};

const getUserId = () => {
    return window.localStorage.getItem('tui-user-id');
};

const sendEvent = (url, tenant, name, data) => {
    window.fetch(`${url}/crystal-tracker-service/current/trackEvent/${tenant}`,
        {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
        .then((response) => {
            if (!response.ok) {
                throw Error(response.statusText);
            }
            return response.json();
        })
        .then((res) => {
            return res;
        })
        .catch((error) => {
            return error;
        });
};

export {
    trackProductListEvent,
    trackProductDetailEvent,
    trackBookingEvent
}
